import { Card } from "day8-ui";
import { IoIosStar } from "@react-icons/all-files/io/IoIosStar";
import Image from "next/image";

interface ReviewProps {
  reviewContent: string;
  reviewAuthor: string;
  reviewSocialTag: string;
  reviewImgUrl?: string;
  reviewStars: number;
}

const Review = ({ reviewAuthor, reviewContent, reviewSocialTag, reviewImgUrl, reviewStars }: ReviewProps) => {
  return (
    <Card className="dui-u-custom-theme-invert dui-w-full dui-p-4 md:dui-p-6 !dui-bg-[#E9F3EE] dui-flex dui-flex-col dui-justify-center dui-text-primary-950 dui-border-solid dui-border-primary-950 dui-border-2">
      <div className='dui-flex dui-items-center dui-mb-6'>
        {reviewImgUrl && <Image src={reviewImgUrl} alt="review image" className="dui-w-14 dui-h-14 dui-object-cover dui-rounded-full dui-mr-4" width={400} height={400} />}
        <div>
          <h3 className="dui-text-2xl dui-font-heading !dui-font-normal dui-mb-2 dui-text-primary-950 dui-uppercase">{reviewAuthor}</h3>
          <p>{reviewSocialTag}</p>
        </div>
      </div>
      <div className='dui-grow'>
        <div className="dui-flex dui-items-center dui-mb-6">
          {Array(reviewStars).fill(null).map((_, index) => <IoIosStar className="dui-text-secondary-500 dui-mr-2 dui-text-2xl" key={index} />)}
        </div>
        <p className="dui-mb-8 dui-max-w-2xl dui-mx-auto">{reviewContent}</p>
      </div>
    </Card>
  )
}

export default Review;