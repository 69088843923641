import React from "react";
import Image from "next/image";
import { analyticsHelpers, Card, constants } from "day8-ui";
import KlaviyoSignup from "./forms/KlaviyoSignup";
import Spacer from "./Spacer";

const { ADDRESS_BOOKS } = constants;
const SignUp5050 = ({ addressBookId, heading, paragraph, buttonText }: { addressBookId?: string, heading?: string, paragraph?: string, buttonText?: string }) => {
    const { GA4_TRIGGERS } = analyticsHelpers;
    return (
        <>
            <div id="footer-sign-up" className='dui-bg-sunny-yellow-100 dui-border-y dui-border-solid dui-border-t-4 dui-border-b-4 dui-border-sunny-yellow-500'>
                <Spacer />
                <div className="dui-container dui-mx-auto">
                    <Card className="!dui-p-0 dui-min-h-0">
                        <div className="dui-flex dui-flex-col md:dui-flex-row dui-w-full">
                            <div className="dui-hidden lg:dui-block lg:dui-w-1/2 dui-relative">
                                <Image
                                    alt="image"
                                    className="dui-w-full dui-h-[350px] dui-min-h-full dui-object-cover"
                                    src="https://assets.theyachtweek.com/wp-content/uploads/2024/09/TOFU-LP-SKIP-THE-QUEUE-1.jpg"
                                    height={200}
                                    width={400}
                                />
                            </div>
                            <div className="dui-w-full lg:dui-w-1/2 lg:dui-px-16 lg:dui-py-10 dui-p-4 dui-bg-sunny-yellow-100 dui-flex dui-flex-col dui-justify-center dui-text-primary-950">
                                <h3 className="dui-text-5xl dui-font-heading !dui-font-normal dui-mb-8 dui-text-primary-950 dui-uppercase">{heading || "Get the inside line."}</h3>
                                <p className="dui-mb-8 ">{paragraph || "Get first-hand accounts from other Yacht Weekers. Be the first to see upcoming discounts and deals. And find our destination must-sees: from historic castle hikes to island-hopping cheat sheets."}</p>
                                <KlaviyoSignup id={GA4_TRIGGERS.SIGNUP_FORM_ID} addressBookId={addressBookId || ADDRESS_BOOKS["tyw-footer"]} buttonText={buttonText || "Let's Go"} customFields={{
                                    "MOFU__LP__DESTINATION": "GENERIC",
                                    Source: "TOFU | ALL",
                                    Persona: "Generic",
                                }} />
                            </div>
                        </div>
                    </Card>
                </div>
                <Spacer />
            </div>
            {/* <Spacer /> */}
        </>
    )
}

export default SignUp5050;