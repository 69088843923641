import React from 'react';
import { Button, Divider } from "day8-ui"
import { IoMdArrowForward } from "@react-icons/all-files/io/IoMdArrowForward";
import Link from 'next/link';
import { useRouter } from 'next/router'
import { NavigationMenuContentDataItem } from './Navbar';

type NavigationMenuContentProps = {
  sectionTitle: string
  data: NavigationMenuContentDataItem[]
}
export default function NavigationMenuContent({ sectionTitle, data }: NavigationMenuContentProps) {
  const [activeItem, setActiveItem] = React.useState(0);
  const router = useRouter()
  const currentItem = data[activeItem] || null;
  return (
    <div className="dui-m-0 dui-grid dui-grid-cols-4 dui-gap-4 dui-p-[22px] sm:dui-w-[900px]">
      <div className='dui-col-span-1 dui-border-r-[1px] dui-pr-4'>
        <p className='dui-text-gray-400'>{sectionTitle}</p>
        <div className='dui-grid'>
          {data.map((item, i) => (
            <div key={i} onMouseOver={() => setActiveItem(i)}>
              <Button className={`${i === activeItem ? 'dui-bg-primary-50' : ''} !dui-justify-start dui-text-left dui-w-full`} buttonType='TERTIARY' onClick={() => currentItem.link ? router.push(currentItem.link) : setActiveItem(i)}>{item.buttonText}</Button>
            </div>
          ))}
        </div>
      </div>
      {currentItem && (
        <div className='dui-col-span-3 dui-grid dui-pl-4'>
          <div className='dui-flex dui-gap-4 dui-justify-between'>
            <div>
              <h2 className='dui-font-body dui-text-lg'>{currentItem.title}</h2>
              <p>{currentItem.description}</p>
              {currentItem.link && (
                <Link href={currentItem.link}>
                  <Button className='dui-mt-4' buttonType='LINK_UNDERLINE' rightIcon={<IoMdArrowForward />}>Learn more</Button>
                </Link>
              )}
            </div>
            {currentItem?.image && (
              <div className='dui-w-[50%] dui-shrink-0'>
                <div className='dui-relative dui-aspect-[16/9]'>
                  <img
                    src={currentItem?.image?.url || ''}
                    alt={currentItem?.image?.alt || ''}
                    className="dui-absolute dui-w-full dui-h-full dui-object-cover"
                  />
                </div>
              </div>
            )}
          </div>
          {currentItem?.additionalLinks && currentItem.additionalLinks?.length > 0 && (
            <>
              <Divider className='dui-my-4' />
              <div>
                <h2 className='dui-font-body dui-text-lg'>{currentItem?.additionalLinksTitle}</h2>
                {currentItem.additionalLinks.map((item) => (
                  <Link href={item.link} >
                    <Button className='dui-mt-2' buttonType='LINK_UNDERLINE' rightIcon={<IoMdArrowForward />}>{item.title}</Button>
                  </Link>
                ))}
              </div>
            </>
          )}

        </div>
      )}
    </div>
  )
}

