import React from 'react';
import { Button, Divider, AccordionTrigger, AccordionContent } from "day8-ui";
import Link from "next/link";
import { IoMdArrowForward } from "@react-icons/all-files/io/IoMdArrowForward";
import { NavigationMenuContentDataItem } from "./Navbar";
import Image from "next/image";
import { useRef, } from "react";
import { useRouter } from 'next/router';

type MobileMenuNestedItemProps = {
  item: NavigationMenuContentDataItem
  scrollToSection: (ref: React.RefObject<HTMLDivElement>) => void,
}
export default function MobileMenuNestedItem({ item, scrollToSection }: MobileMenuNestedItemProps) {
  const router = useRouter()
  const scrollTargetRef = useRef(null);
  return (
    <div ref={scrollTargetRef} onClick={() => scrollToSection(scrollTargetRef)}>
      <AccordionTrigger className="dui-group dui-w-full">
        <Button className={`group-data-[state=open]:dui-bg-primary-50 dui-flex dui-justify-between dui-w-[100%]`} buttonType='LINK' onClick={() => !item.additionalLinks?.length && item.link ? router.push(item.link) : scrollToSection(scrollTargetRef)}>
          <span className='dui-p-4'>{item.buttonText}</span>
        </Button>
      </AccordionTrigger>
      <AccordionContent className="dui-p-4">
        <div className='dui-flex dui-gap-4 dui-mb-4'>
          {item.image && (
            <div className="dui-w-full dui-relative dui-aspect-[4/3] dui-overflow-hidden">
              <Image className="dui-absolute dui-inset-0 dui-w-full dui-h-full dui-object-cover dui-object-center" src={item.image.url} alt={item.image?.alt || item.title} width={1024} height={768} />
            </div>
          )}
          <div>
            <h3 className="dui-font-body dui-text-lg dui-mb-2">{item.title}</h3>
            <p>{item.description}</p>
            {item.link && (
              <Link href={item.link} >
                <Button className="dui-mt-4 dui-mb-2" buttonType="LINK_UNDERLINE" rightIcon={<IoMdArrowForward />}>
                  Learn more
                </Button>
              </Link>
            )}
          </div>
        </div>
        <Divider />
        {!!item.additionalLinks?.length && (
          <div>
            <h3 className="dui-font-body dui-text-lg dui-my-2">{item?.additionalLinksTitle}</h3>
            {item.additionalLinks?.map(festival => (
              <Link href={festival.link}>
                <Button className="dui-mt-2" buttonType="LINK_UNDERLINE" rightIcon={<IoMdArrowForward />}>
                  {festival.title}
                </Button>
              </Link>
            ))}
          </div>
        )}
      </AccordionContent>
    </div>
  )
}