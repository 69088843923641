import { Button } from "day8-ui";
import { IoIosGlobe } from "@react-icons/all-files/io/IoIosGlobe";
import { LuckyModalFormData, ModalHeadProps } from '../LuckyModal';
import { useLuckyModal } from '../../../context/LuckyModalContext';
import { useEffect } from 'react';

type CountriesProps = {
  formData: LuckyModalFormData;
  handleChange: (e: any) => void
  setModalHeadProps: (props: ModalHeadProps | null) => void
  onNext: () => void
}

const Countries: React.FC<CountriesProps> = ({ formData, handleChange, setModalHeadProps, onNext }) => {
  const { countries } = useLuckyModal();
  useEffect(() => {
    setModalHeadProps({
      icon: <IoIosGlobe />,
      title: "Where to?",
      subtitle: "",
    })
  }, [])
  const handleDestinationSelect = (country: string | null) => {
    handleChange({ target: { value: country, name: 'countryFilter' } });
    onNext();
  };
  if (!countries?.length) {
    return <Button buttonType='SECONDARY' onClick={onNext}>I'm Flexible</Button>
  }
  return (
    <div className="dui-grid dui-grid-cols-1 dui-gap-4">
      {countries.map(country => <Button key={country} buttonType={country === formData.countryFilter ? 'SECONDARY_2' : 'SECONDARY'} onClick={() => handleDestinationSelect(country)}>{country}</Button>)}
      <Button buttonType={!formData.countryFilter ? 'SECONDARY_2' : 'SECONDARY'} onClick={() => handleDestinationSelect(null)}>I'm flexible</Button>
    </div>
  )
};

export default Countries;