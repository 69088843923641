import type { GetStaticProps, NextPage } from 'next';
import wp, { sanitizeDestinationResponse, sanitizeWpResponse } from '../helpers/wp';
import { getDestinations, getFestivals, getWpOptionsPages } from '../helpers/api';
import { BgImageType, CTAType, DestinationType, TYWOptionPages, WpPageType } from '../globalTypes';
import HomepageWrapper from '../components/HomepageWrapper';

export type FrontPage = {
  optionsPages: TYWOptionPages
  page: WpPageType & {
    acf: {
      flex_sticky_banner?: { text: string, link: string }
      homeheroslides: {
        title: string,
        subtitle: string,
        background: BgImageType
        video: any
        mobile_background: any
        mobile_video: any
        countdown?: string
        button: CTAType
      }[]
    }
  }
  festivals: DestinationType[]
}

const trustPilot = {
  link: '#reviews',
  score: '4-5',
  color: '#ffffff'
};

export const destinationSlugs = ['croatia', 'greece', 'sardinia', 'sardinia-refined-route', 'caribbean'];
export const destinationFestivals = ['croatia-drumcode', 'ultra', 'greece-applebum', 'greece-peche', 'rhythm-waves', 'vibras'];

const Home: NextPage<FrontPage> = props => <HomepageWrapper page={props.page} optionsPages={props.optionsPages} festivals={props.festivals} />;
export const getStaticProps: GetStaticProps = async () => {
  try {
    const optionsPages: TYWOptionPages = await getWpOptionsPages();
    const pages: WpPageType[] = await wp.pages().slug('frontpage').get();
    const festivals = await getFestivals();
    return {
      props: {
        page: sanitizeWpResponse(pages[0]) || null,
        optionsPages: optionsPages || null,
        festivals: festivals || []
      },
      revalidate: 300
    };
  } catch (e) {
    return { notFound: true, revalidate: 300 };
  }
};

export default Home;
