import { constants } from 'day8-ui';
const { FEATURED_WEEKS } = constants;

export const getFeaturedWeekPillData = (weekId, isWeekPill) => {
  const isFeatured = Object.keys(FEATURED_WEEKS).includes(String(weekId));
  if (!isFeatured) {
    return null;
  }
  const pillData = FEATURED_WEEKS[weekId][isWeekPill ? 'weekPill' : 'featuredPill'];
  if (!pillData) {
    return null;
  }
  return pillData;
};

export const getConfirmEventName = (country, route, weekId) => {
  const pillData = getFeaturedWeekPillData(weekId);
  let text = country;
  if (pillData?.title || route) {
    text += `, ${pillData?.title || route}`;
  }
  return text;
};

export const getOriginalFestivals = (festivals) => {
  return festivals.filter(festival => (festival.acf?.destination_id && +festival.acf.destination_id == 3) || !festival.acf?.week_id);
};

export const getSpecialFestivals = (festivals) => {
  return festivals.filter(festival => (festival.acf?.destination_id && +festival.acf.destination_id != 3) && festival.acf?.week_id);
};
