import { Button, AccordionTrigger, AccordionContent, Accordion, AccordionItem } from "day8-ui";
import Link from "next/link";
import { FaChevronUp } from "@react-icons/all-files/fa/FaChevronUp";
import { NavigationMenuContentDataItem } from "./Navbar";
import { useRef, } from "react";
import MobileMenuNestedItem from './MobileMenuNestedItem';

type MobileMenuItemProps = {
  label: string,
  href?: string,
  data?: NavigationMenuContentDataItem[],
  setIsActive: () => void,
  isActive: boolean
}
export default function MobileMenuItem({ data, label, href }: MobileMenuItemProps) {
  const scrollTargetRef = useRef(null);
  const scrollToSection = (scrollRef: React.RefObject<HTMLDivElement>) => {
    // Delay the scroll action slightly to allow the accordion to finish expanding
    setTimeout(() => {
      if (scrollRef.current) {
        scrollRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    }, 100); // Adjust delay based on the accordion's animation duration
  };
  return (
    <div className="dui-py-4">
      {href && (
        <Link href={href}>
          <Button className="dui-flex dui-justify-between dui-w-[100%]" buttonType='LINK'>{label}</Button>
        </Link>)}
      {!href && (
        <div ref={scrollTargetRef} onClick={() => scrollToSection(scrollTargetRef)}>
          <AccordionTrigger className="dui-group dui-w-full">
            <Button className="dui-flex dui-justify-between dui-w-[100%]" buttonType='LINK'>
              <span>{label}</span>
              <FaChevronUp className="dui-transition-transform dui-duration-[250ms] dui-ease-in group-data-[state=closed]:dui-rotate-180" />
            </Button>
          </AccordionTrigger>
        </div>
      )}
      {data && (
        <AccordionContent className="dui-transition-transform dui-duration-[250ms] dui-ease-in">
          <Accordion className="dui-mt-4 dui-border dui-border-solid dui-border-gray-200">
            {data.map((item, i) => (
              <AccordionItem key={i} value={item.buttonText}>
                <MobileMenuNestedItem item={item} scrollToSection={scrollToSection} />
              </AccordionItem>
            ))}
          </Accordion>
        </AccordionContent>
      )}
    </div>
  )
}