import { MdFormatQuote } from "@react-icons/all-files/md/MdFormatQuote";
import Spacer from "../Spacer";
import Image from "next/image";

const Testimonial = ({ logo, quote, author }: { author: string, logo?: string, quote: string }) => {
  return (
    <div className="dui-bg-primary-950 dui-px-2 md:dui-px-8 dui-h-full">
      <Spacer />
      <div className='dui-container dui-mx-auto dui-text-center'>
        <div className='relative dui-w-1/5 dui-mx-auto dui-mb-6'>
          <div className='dui-h-[2px] dui-w-full dui-bg-secondary-500'></div>
          <div className='dui-bg-primary-950 dui-px-2 dui-flex dui-justify-center dui-items-center dui-absolute dui-left-1/2 dui-top-1/2 dui--translate-x-1/2 dui--translate-y-1/2'>
            <MdFormatQuote className='dui-text-white dui-text-4xl' />
          </div>
        </div>
        <h2 className='dui-text-5xl dui-leading-tight md:dui-text-[70px] lg:dui-text-[100px] !dui-font-normal dui-font-heading dui-uppercase dui-text-white'>{quote}</h2>
        <div className='relative dui-w-1/5 dui-mx-auto dui-mt-8'>
          <div className='dui-h-[2px] dui-w-full dui-bg-secondary-500'></div>
          <div className='dui-bg-primary-950 dui-px-2 dui-flex dui-justify-center dui-items-center dui-absolute dui-left-1/2 dui-top-1/2 dui--translate-x-1/2 dui--translate-y-1/2'>
            <MdFormatQuote className='dui-text-white dui-text-4xl dui-rotate-180' />
          </div>
        </div>
      </div>
      {logo && <Image className='dui-mx-auto dui-max-w-1/2 dui-w-[200px] dui-mt-4 lg:dui-mt-12' src={logo} width="300" height="150" alt={author} />}
      {!logo && author && <div className='dui-text-center dui-px-6 dui-mt-4 lg:dui-mt-12'><p className='dui-text-4xl dui-font-bold dui-pb-0 dui-font-subheading dui-text-white'>{author}</p></div>}

      <Spacer />
    </div>
  )
}

export default Testimonial;