import { Button, Carousel, Card, FormatCurrency } from "day8-ui";
import { PiCaretRightLight } from "@react-icons/all-files/pi/PiCaretRightLight";
import { PiCaretLeftLight } from "@react-icons/all-files/pi/PiCaretLeftLight";
import { DestinationType } from "../globalTypes";
import Link from "next/link";
import Image from "next/image";

interface FestivalSliderProps {
    festivals: DestinationType[]
    title?: string
    itemStyleType?: "LIGHT" | "PRIMARY_2"
    carouselSettings?: {
        arrows?: boolean
        dots?: boolean
        infinite?: boolean
        autoplay?: boolean
        autoplaySpeed?: number
        slidesToShow?: number
        slidesToScroll?: number
        responsive?: {
            breakpoint: number
            settings: {
                slidesToShow: number
            }
        }[]
    }
}

const ITEM_TYPES = {
    LIGHT: "dui-bg-white",
    PRIMARY_2: "dui-bg-secondary-100"
}

const FestivalSlider = ({ festivals, title, itemStyleType = "PRIMARY_2", carouselSettings }: FestivalSliderProps) => {
    return (
        <>
            {title && (
                <div className="dui-container dui-mx-auto">
                    <div className='dui-text-center'>
                        <h3 className="dui-text-6xl dui-mb-14 lg:dui-mb-8 dui-font-heading !dui-font-normal dui-text-primary-950 dui-uppercase">{title}</h3>
                    </div>
                </div>
            )}
            {festivals && (
                <div className="dui-container dui-mx-auto">
                    <Carousel
                        className="dui-w-full sm:dui-mx-[-10px]"
                        settings={{
                            arrows: true,
                            dots: false,
                            infinite: festivals.length > 3,
                            autoplay: festivals.length > 3,
                            autoplaySpeed: festivals.length > 3 ? 3000 : 0,
                            nextArrow: <Button buttonType="CAROUSEL"><span className="dui-flex dui-items-center dui-justify-center"><PiCaretRightLight className='dui-text-2xl' /></span></Button>,
                            prevArrow: <Button buttonType="CAROUSEL"><span className="dui-flex dui-items-center dui-justify-center"><PiCaretLeftLight className='dui-text-2xl' /></span></Button>,
                            slidesToScroll: 1,
                            slidesToShow: 3,
                            responsive: [
                                {
                                    breakpoint: 768,
                                    settings: {
                                        slidesToShow: 1
                                    }
                                },
                                {
                                    breakpoint: 1024,
                                    settings: {
                                        slidesToShow: 2
                                    }
                                }
                            ],
                            ...carouselSettings
                        }}
                        includePageStatus={false}
                        slides={festivals.map((item: DestinationType, index) => (
                            <div key={index} className='sm:dui-px-[10px]'><FestivalCard item={item} itemStyleType={itemStyleType} /></div>
                        ))}
                    />
                </div>
            )}
        </>
    )
}

export default FestivalSlider;


const FestivalCard = ({ item, itemStyleType = "PRIMARY_2" }: { item: DestinationType, itemStyleType: "LIGHT" | "PRIMARY_2" }) => {
    const itemStyle = ITEM_TYPES[itemStyleType]
    return (
        <Card className="dui-min-h-[500px] !dui-p-0 dui-w-full dui-relative dui-flex dui-flex-col dui-justify-between">
            <Image className="dui-brightness-75 dui-absolute dui-z-2 dui-w-full dui-h-full dui-object-cover dui-left-0 dui-top-0" src={item.featured_image_src || "https://assets.theyachtweek.com/wp-content/uploads/2024/08/special-floating-festival.png"} alt={item.festivalName || "item sample image"} width={600} height={600} />
            <div className={`${itemStyle} dui-w-full dui-relative dui-py-1 dui-px-4 dui-font-bold dui-text-primary-950 dui-uppercase dui-border-b-4 dui-border-solid dui-border-secondary-500`}>
                {item.minPrice ? (
                    <>
                        FROM <FormatCurrency amount={item.minPrice} baseCurrency={item.currency || 'EUR'} />
                    </>
                ) : (
                    <>
                        {item.festivalStatus}
                    </>
                )}
            </div>
            <div className="dui-relative dui-text-white dui-p-8">
                <h2 className="dui-font-heading dui-text-6xl dui-uppercase dui-text-white dui-mb-2">{item.festivalName}</h2>
                <p className="lg:dui-text-lg">{item.destinationSubtitle}</p>
                {item.slug && (
                    <Link href={item.slug || "#"}>
                        <Button className="dui-font-subheading dui-font-bold dui-mt-2" buttonType="PRIMARY_2">Explore</Button>
                    </Link>
                )}
            </div>
        </Card>
    )
}