import { IoCalendarClearOutline } from "@react-icons/all-files/io5/IoCalendarClearOutline";
import { Select, Button } from 'day8-ui'
import { LuckyModalFormData, ModalHeadProps } from '../LuckyModal';
import { useLuckyModal } from '../../../context/LuckyModalContext';
import { useCallback, useEffect } from 'react';

type CalendarProps = {
  formData: LuckyModalFormData;
  handleChange: (e: any) => void
  setModalHeadProps: (props: ModalHeadProps | null) => void
  onNext: () => void
}
type Month = {
  name: string;
  icon: string;
  id: number;
};

const MONTHS: Month[] = [{ name: 'January', icon: 'calendar', id: 0 }, { name: 'February', icon: 'calendar', id: 1 }, { name: 'March', icon: 'calendar', id: 2 }, { name: 'April', icon: 'calendar', id: 3 }, { name: 'May', icon: 'calendar', id: 4 }, { name: 'June', icon: 'calendar', id: 5 }, { name: 'July', icon: 'calendar', id: 6 }, { name: 'August', icon: 'calendar', id: 7 }, { name: 'September', icon: 'calendar', id: 8 }, { name: 'October', icon: 'calendar', id: 9 }, { name: 'November', icon: 'calendar', id: 10 }, { name: 'December', icon: 'calendar', id: 11 }];

const Calendar: React.FC<CalendarProps> = ({ formData, handleChange, setModalHeadProps, onNext }) => {
  const { destinations, countries } = useLuckyModal();
  // if any year selected , filter other weeks out
  const allWeeks = destinations.flatMap(destination => destination.weeks.filter(w => formData.yearFilter ? +w.year === +formData.yearFilter : true)).filter(w => !w.invisible && w.status !== 'sold_out');
  const uniqueYears = [...new Set(allWeeks.map(week => week.year))];

  // if any year selected , filter other weeks out
  const activeDestinationWeeks = formData.countryFilter && destinations.find(dest => dest.country === formData.countryFilter)?.weeks.filter(w => formData.yearFilter ? +w.year === +formData.yearFilter : true) || [];
  let enabledMonthsForActiveDestination = formData.countryFilter && activeDestinationWeeks.filter(week => !['sold_out', 'closed'].includes(week.status)).flatMap(week => new Date(week.beginDate).toLocaleString('default', { month: 'long' }));
  if (!formData.countryFilter) {
    enabledMonthsForActiveDestination = allWeeks.flatMap(week => new Date(week.beginDate).toLocaleString('default', { month: 'long' }));
  }

  useEffect(() => {
    if (uniqueYears.length > 0) {
      handleChange({ target: { value: uniqueYears[0], name: 'yearFilter' } });
    }
    setModalHeadProps({
      icon: <IoCalendarClearOutline />,
      title: "When can you go?",
      subtitle: "If you have a specific month you can travel select here, or skip to the next step to see all options.",
    })
  }, [])

  useEffect(() => {
    // reset month when destination or year changes and previously selected month is not available anymore
    const monthObject = MONTHS.find(m => formData.month.includes(+m.id));
    if (monthObject && enabledMonthsForActiveDestination && !enabledMonthsForActiveDestination.includes(monthObject.name)) {
      handleChange({ target: { value: [], name: 'month' } });
    }
  }, [formData.countryFilter, formData.yearFilter]);

  const isMonthSelected = (month: number) => formData.month.includes(month);
  const handleMonthSelect = useCallback((month: number | null) => {
    let updatedMonths = !month ? [] : formData.month;
    if (month) {
      updatedMonths = updatedMonths.includes(month) ? updatedMonths.filter(m => m !== month) : [...updatedMonths, month]
    }
    handleChange({ target: { value: updatedMonths, name: 'month' } });
    if (!month) {
      onNext();
    }
  }, [handleChange]);

  const handleCountrySelect = useCallback((country: string | null) => {
    handleChange({ target: { value: country, name: 'countryFilter' } });
  }, [handleChange]);

  const handleYearSelect = useCallback((year: number | null) => {
    handleChange({ target: { value: year, name: 'yearFilter' } });
  }, [handleChange]);

  // const isDestinationActive = (destinationId: number) => formData.countryFilter === destinationId;
  // const getDestinationButtonText = (destinationId: number | null) => destinationId && destinations.find(dest => +dest.id === +destinationId)?.name || 'All destinations';
  const hasMultipleUniqueYears = uniqueYears.length > 1;
  // const hasMultipleUniqueYears = true;
  const activeMonths = MONTHS.filter(month => enabledMonthsForActiveDestination && enabledMonthsForActiveDestination.includes(month.name));
  return (
    <div>
      {hasMultipleUniqueYears &&
        <div className='dui-grid dui-grid-cols-2 dui-gap-4 dui-mb-8'>
          {/* <Select
        containerClassName={`dui-w-full dui-col-span-2 ${!!hasMultipleUniqueYears && 'sm:dui-col-span-1'}`}
        className={`dui-w-full ${hasMultipleUniqueYears ? 'dui-col-span-1' : 'dui-col-span-2'}`}
        defaultValue={`All countries`}
        defaultLabel={'All countries'}
        value={formData.countryFilter ? `${formData.countryFilter}` : 'All countries'}
        onChange={(e) => handleCountrySelect(e.target.value)}
        options={countries?.map(country => ({ text: country, value: country })) || []}
      /> */}
          {hasMultipleUniqueYears && (
            <Select
              containerClassName='dui-col-span-2 sm:dui-col-span-1'
              className='dui-w-full'
              onChange={(e) => handleYearSelect(+e.target.value)}
              options={uniqueYears.map(year => ({ text: `${year}`, value: `${year}` }))}
            />
          )}
        </div>
      }
      <div>
        <div className='dui-grid dui-grid-cols-3 dui-gap-4 dui-mb-8'>
          {activeMonths.map(month => (
            <Button key={month.name} className="dui-shrink-0" active={isMonthSelected(month.id)} buttonType={formData.month.includes(+month.id) ? 'SECONDARY_2' : 'SECONDARY'} onClick={() => handleMonthSelect(month.id)} disabled={!!enabledMonthsForActiveDestination && !enabledMonthsForActiveDestination.includes(month.name)}>
              <div className="dui-flex dui-flex-col dui-justify-between dui-items-center">
                <IoCalendarClearOutline />
                <div className="dui-text-center dui-mt-4">
                  <h3 className={`d8-u-font-size-base d8-u-font-weight-500`}>{month.name}</h3>
                </div>
              </div>
            </Button>
          ))}
        </div>
        <Button className="dui-w-full" buttonType={"SECONDARY"} onClick={() => handleMonthSelect(null)}>I'm flexible</Button>
        <p className='dui-mt-4 dui-text-center dui-min-h-[24px]'>
          {formData.month?.length > 0 ? (<>You choose {formData.month.map((m, i) => <span className='dui-font-semibold'>{i > 0 && `, `}{MONTHS.find(month => +m === +month.id)?.name}</span>)}</>) : ""}
        </p>
      </div>
    </div>
  )

};

export default Calendar;