import React from 'react';
import TywMiniLogo from '../svg/TywMiniLogo';
import Link from 'next/link';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger, Button, Divider } from 'day8-ui';
import { FaFacebook } from "@react-icons/all-files/fa/FaFacebook";
import { FaInstagram } from "@react-icons/all-files/fa/FaInstagram";
import { FaTiktok } from "@react-icons/all-files/fa/FaTiktok";
import { FaYoutube } from "@react-icons/all-files/fa/FaYoutube";
import { FaChevronUp } from "@react-icons/all-files/fa/FaChevronUp";
import Spacer from '../Spacer';
import { IoMdArrowForward } from '@react-icons/all-files/io/IoMdArrowForward';
import TrustpilotWidget from '../TrustpilotWidget';

type FooterConfig = {
  label: string
  href?: string
  links?: {
    label: string
    href: string
  }[]
}[]

const Footer = ({ footerConfig, trustpilotTheme = 'dark' }: { footerConfig: FooterConfig, trustpilotTheme?: "light" | "dark" }) => {
  return (
    <div className='dui-bg-primary-900 dui-text-white'>
      <Spacer />
      <div className='dui-container dui-mx-auto'>
        {/* <div className='dui-flex dui-flex-col md:dui-flex-row dui-justify-between dui-gap-4'> */}
        <div className='dui-flex dui-flex-col md:dui-flex-row dui-gap-8'>
          <div className='dui-w-full md:dui-w-1/3 lg:dui-w-[40%]'>
            <div className='dui-flex dui-items-center dui-gap-4'>
              <div className='dui-bg-white dui-p-1 dui-w-[40px] dui-h-[40px]'>
                <Link href="/">
                  <TywMiniLogo className='dui-w-full dui-h-full' fill="var(--dui-color-black)" />
                </Link>
              </div>
              <p className='dui-text-xl dui-font-semibold'>Yacht Week</p>
            </div>

            <p className='dui-mt-8 dui-text-sm'>
              Yacht Week brand and concept is owned by Day 8 AB - a private limited company registered in Stockholm, Sweden. Day 8 Experiences Ltd acts as a travel agent on the behalf of Yacht Week.
            </p>
            <div className='dui-flex dui-items-start dui-justify-start dui-max-w-[230px]'>
              <TrustpilotWidget className='dui-mt-8 dui-bg-transparent' theme={trustpilotTheme} template='micro' />
            </div>
          </div>
          <div className='dui-hidden sm:dui-flex sm:dui-justify-between dui-w-full dui-gap-4'>
            {footerConfig?.map(item => (
              <div className='dui-flex dui-flex-col dui-gap-4'>
                {!!item.href && <Link href={item.href} className=''><span className='dui-uppercase dui-font-normal dui-text-sm' >{item.label}</span></Link>}
                {!item.href && <div className=''><span className='dui-uppercase dui-font-normal dui-text-sm' >{item.label}</span></div>}
                {item.links?.map(link => (
                  <>
                    {link.href && link.href.includes('terms-and-conditions') ? <a href={link.href} rel="noopener noreferrer" className='dui-font-normal dui-text-sm' >{link.label}</a> : <Link href={link.href} className='dui-font-normal dui-text-sm' >{link.label}</Link>}
                  </>
                ))}
              </div>
            ))}
          </div>
          <div className='sm:dui-hidden'>
            <Accordion className="dui-flex-1 dui-overflow-y-auto dui-py-2 dui-flex dui-flex-col dui-gap-4" collapsible>
              {footerConfig?.map((item, index) => (
                <AccordionItem key={index} value={item.label}>
                  <AccordionTrigger className="dui-group dui-w-full">
                    <Button className="dui-flex dui-justify-between dui-w-[100%] dui-text-white" buttonType='LINK'>
                      <span>{item.label}</span>
                      <FaChevronUp className="dui-transition-transform dui-duration-[250ms] dui-ease-in group-data-[state=closed]:dui-rotate-180" />
                    </Button>
                  </AccordionTrigger>
                  <AccordionContent className='dui-text-white'>
                    {item.links?.map(link => (
                      <>
                        <Link href={link.href}>
                          <Button className="dui-mt-4 dui-text-white" buttonType="LINK" buttonSize='SMALL' rightIcon={<IoMdArrowForward />}>
                            {link.label}
                          </Button>
                        </Link>
                      </>
                    ))}
                  </AccordionContent>
                  <Divider className='dui-mt-4' />
                </AccordionItem>
              ))}
            </Accordion>
          </div>
        </div>
        <Divider className='dui-hidden sm:dui-block dui-my-8' />
        <div className='dui-mt-8 sm:dui-mt-0 dui-flex dui-items-center dui-justify-between'>
          <div>
            <p className='dui-font-medium dui-text-sm'>© {new Date().getFullYear()} Yacht week. All rights reserved.</p>
          </div>
          <div className='dui-flex dui-gap-4'>
            <a href="https://www.facebook.com/theyachtweek" target='_blank' rel="noopener noreferrer">
              <FaFacebook className='dui-w-[22px] dui-h-[22px]' />
            </a>
            <a href="https://www.instagram.com/theyachtweek/" target='_blank' rel="noopener noreferrer">
              <FaInstagram className='dui-w-[22px] dui-h-[22px]' />
            </a>
            <a href="https://www.tiktok.com/@theyachtweek" target='_blank' rel="noopener noreferrer">
              <FaTiktok className='dui-w-[22px] dui-h-[22px]' />
            </a>
            <a href="https://www.youtube.com/user/TheYachtWeek" target='_blank' rel="noopener noreferrer">
              <FaYoutube className='dui-w-[22px] dui-h-[22px]' />
            </a>
          </div>
        </div>
      </div>
      <Spacer />
    </div>
  )
};

export default Footer;