// import { getInstagram, getTikTokVideos } from 'day8-react/build/content';
import envConstants from 'day8-react/build/env-constants';
import wp, { sanitizeWpResponse } from './wp';
import { CATEGORY_DESTINATION_2025, CATEGORY_DESTINATIONS_INVISIBLE, CATEGORY_PRODUCT_2025 } from '../pages/[...slug]';
import { constants } from 'day8-ui';
import { getConfirmEventName } from './destination';

const { FEATURED_WEEKS } = constants;

const rootUrl = envConstants.ENV.API_URL || 'https://www.theyachtweek.com';

export const getDestinations = async (onlyInvisible = false) => {
  const cmsDestinations = await wp.pages().param('categories', [CATEGORY_DESTINATION_2025]).get();
  const ylDestinations = await fetch(`${rootUrl}/api/yl/destinations`).then(res => res.json());

  const destinations = cmsDestinations && cmsDestinations.map(d => {
    const matchingTywDest = ylDestinations.find(dest => dest.id === parseInt(d.acf.destination_id, 10));
    const prices = matchingTywDest && matchingTywDest.weeks
      .filter(w => w.fromPrices?.general)
      .filter(w => onlyInvisible ? w.invisible : !w.invisible)
      .filter(w => {
        const isWeekStatusValid = !['sold_out', 'closed', 'coming_soon'].includes(w.status);
        const isCabinStatusValid = !['sold_out', 'closed', 'coming_soon'].includes(w.cabinStatus);
        return isWeekStatusValid || isCabinStatusValid;
      })
      .map(w => w.fromPrices?.general);
    const minPrice = prices && !!prices.length && Math.min(...prices) || null;
    return {
      ...sanitizeWpResponse(d),
      id: matchingTywDest && matchingTywDest.id,
      minPrice,
      destinationStatus: matchingTywDest && matchingTywDest.status || null,
      standardYachts: matchingTywDest?.standardYachts || [],
      weeks: matchingTywDest ? matchingTywDest.weeks.filter(w => onlyInvisible ? w.invisible : !w.invisible) : [],
    };
  });
  return destinations.sort((a, b) => a.menu_order - b.menu_order);
};
export const getTYWDestinations = async () => {
  try {
    const ylDestinations = await fetch(`${rootUrl}/api/yl/destinations`).then(res => res.json());
    const visibleDestinations = ylDestinations.filter(d => d.weeks.some(w => !w.invisible && w.company.id == 2));
    return visibleDestinations;
  } catch (e) {
    console.error("ERROR:", e);
    return [];
  }
};

export const fetchWeekAutomaticDiscounts = async () => {
  try {
    const res = await fetch(`${rootUrl}/api/yl/week-auto-promo-discounts`, {
      credentials: 'same-origin'
    });
    return await res.json();
  } catch(e) {
    console.error(e);
    return [];
  }
};

export const getNavbarConfig = async () => {
  try {
    // Hardcoding test site to everride hardcoded env variable that points to live (don't judge me...)
    const response = await fetch(`${rootUrl}/api/get-navbar-config`);

    // Check if the response is OK (status 2xx)
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const navbarConfig = await response.json();
    // Ensure the navbarConfig has a 'data' field before accessing it
    return navbarConfig?.data || {};

  } catch (e) {
    console.error("ERROR fetching navbar config:", e);
    return [];  // Return an empty array on error
  }
};

export const getWpOptionsPages = async () => {
  try {
    const response = await fetch(`${rootUrl}/wp-json/acf/v3/options/options`);
    return await response.json();
  } catch (e) {
    console.error("ERROR fetching wp options pages:", e);
    return [];  // Return an empty array on error
  }
}


export const getFestivals = async () => {
  const cmsFestivalDestinations = await wp.pages().param('categories', [CATEGORY_PRODUCT_2025]).get();
  const ylDestinations = await fetch(`${rootUrl}/api/yl/destinations`).then(res => res.json());
  const festivals = cmsFestivalDestinations.filter(d => !d.categories.includes(CATEGORY_DESTINATIONS_INVISIBLE)).map(d => {
    let matchingTywDest = ylDestinations.find(dest => dest.id === parseInt(d.acf.destination_id, 10));
    const prices = matchingTywDest && matchingTywDest.weeks
      .filter(w => w.fromPrices?.general)
      .filter(w => !w.invisible)
      .filter(w => {
        const isWeekStatusValid = !['sold_out', 'closed', 'coming_soon'].includes(w.status);
        const isCabinStatusValid = !['sold_out', 'closed', 'coming_soon'].includes(w.cabinStatus);
        if (d.acf.week_id && w.id !== parseInt(d.acf.week_id, 10)) {
          return false;
        }
        return isWeekStatusValid || isCabinStatusValid;
      })
      .map(w => w.fromPrices?.general);
    const minPrice = prices && !!prices.length && Math.min(...prices) || null;
    let destinationSubtitle = d.excerpt?.rendered || ""
    let festivalStatus = matchingTywDest?.status || "Sold Out"
    if (d.acf?.week_id && FEATURED_WEEKS[+d.acf?.week_id]) {
      destinationSubtitle = FEATURED_WEEKS[+d.acf?.week_id]?.subtitle || destinationSubtitle
      festivalStatus = matchingTywDest?.weeks?.find(w => w.id === +d.acf?.week_id)?.status || "Sold Out"
    } else if (matchingTywDest?.subtitle) {
      destinationSubtitle = matchingTywDest?.subtitle
    }
    const festivalName = matchingTywDest ? getConfirmEventName(matchingTywDest?.country, matchingTywDest?.route, d.acf?.week_id) : d.title?.rendered
    return {
      ...sanitizeWpResponse(d),
      id: matchingTywDest && matchingTywDest.id || null,
      minPrice,
      currency: matchingTywDest?.weeks?.[0]?.currency || 'EUR',
      destinationSubtitle,
      festivalName,
      festivalStatus,
      destinationStatus: matchingTywDest && matchingTywDest.status || "N/A",
      standardYachts: matchingTywDest?.standardYachts || [],
      weeks: matchingTywDest ? matchingTywDest.weeks?.filter(w => !w.invisible) : [],
    };
  });
  return festivals.sort((a, b) => a.menu_order - b.menu_order);
};

export const getInvisibleDestinations = async () => {
  const [ylDestinations] = await Promise.all([
    `${rootUrl}/api/yl/destinations`
  ].map(url => fetch(url))).then(responses => Promise.all(responses.map(r => r.json())));
  const invisibleDestinations = ylDestinations.filter(d => d.weeks.every(week => week.invisible)).map(d => ({
    ...d,
    acf: {
      destination_id: d.id
    },
    weeks: d.weeks
  }));
  return invisibleDestinations;
};

// export const getSocialFeed = async () => {
//   const instaFeed = await getInstagram();
//   const tikTokFeed = await getTikTokVideos();
//   let mergedFeeds = [];
//   if (tikTokFeed?.length >= 5) {
//     tikTokFeed?.forEach((item, i) => {
//       if (instaFeed?.length && instaFeed[i]) {
//         mergedFeeds.push(instaFeed[i]);
//       }
//       mergedFeeds.push(item);
//     });
//   } else {
//     mergedFeeds = instaFeed;
//   }
//   return mergedFeeds || [];
// };

export const getTrustpilot = async () => {
  const trustpilotApiKey = process.env.trustpilotApiKey;
  const includeStars = [4, 5];
  const urls = [
    `https://api.trustpilot.com/v1/business-units/5a2fa6d80a1d740ea8222914?apikey=${trustpilotApiKey}`,
    `https://api.trustpilot.com/v1/business-units/5a2fa6d80a1d740ea8222914/reviews?stars=${includeStars.join()}&apikey=${trustpilotApiKey}`
  ];
  let trustpilotData;
  Promise.all(urls.map(url => fetch(url)))
    .then(responses => Promise.all(responses.map(r => r.json())))
    .then(responses => {

      if (responses.every(r => console.log({ r, apiKey: trustpilotApiKey }) || r && Object.keys(r) && !r.fault)) {
        const { score, numberOfReviews } = responses[0];
        const { reviews } = responses[1];

        const starImageEndpoint = responses[0].links.find(link => link.rel === 'resources-images-stars').href;
        const starStringEndpoint = responses[0].links.find(link => link.rel === 'resources-strings-stars').href;

        // const resourceUrls = [
        //   starImageEndpoint,
        //   starStringEndpoint,
        // ];

        // Trustpilot didn't update the resources links in their api when they updated their scoring system
        // but the endpoints exist. Constructing them manually for now and then should be able to switch to
        // the simpler, commented out array above later

        const resourceUrls = [
          `${starImageEndpoint.slice(0, starImageEndpoint.lastIndexOf('/'))}/${score.stars}`,
          `${starStringEndpoint.slice(0, starStringEndpoint.lastIndexOf('/'))}/${score.stars}`
        ];

        // Array of unique endpoints to get the star images from
        const starLinks = Array.from(
          new Set(
            Object.values(reviews).map(review => (
              review.links.find(link => link.rel === 'resources-images-stars').href
            ))
          )
        );

        Promise.all([...resourceUrls, ...starLinks].map(url => fetch(`${url}?apikey=${trustpilotApiKey}`)))
          .then(resourceResponses => Promise.all(resourceResponses.map(r => r.json())))
          .then(resourceResponses => {
            if (resourceResponses.every(r => r && Object.keys(r) && !r.fault)) {
              const starsImageUrl = resourceResponses[0].starSvg.url;
              const starsString = resourceResponses[1].string;
              const starsSvgs = resourceResponses.slice(resourceUrls.length).map(starResponse => starResponse.starSvg.url);

              const slimReviews = reviews.map(review => {
                // Match the images endpoint in the review to the svg we've already fetched from these endpoints
                const starImageLink = review.links.find(l => l.rel === 'resources-images-stars').href;
                const index = starLinks.findIndex(l => l === starImageLink);
                const starsSvg = starsSvgs[index];

                return {
                  id: review.id,
                  by: review.consumer.displayName,
                  text: review.text,
                  date: review.createdAt,
                  title: review.title,
                  stars: review.stars,
                  starsSvg
                };
              });

              const slimTrustPilotData = {
                score,
                numberOfReviews,
                starsImageUrl,
                starsString,
                reviews: slimReviews
              };
              trustpilotData = slimTrustPilotData;
            } else {
              console.error('Failed fetching Trust Pilot information');
            }
          });
      } else {
        console.error('Failed fetching Trust Pilot information');
      }
    })
    .catch(e => {
      console.error(e);
    });
  return trustpilotData;
};

export const getCountryCode = async () => {
  const result = await fetch(`${rootUrl}/api/account/country-code`, {
    credentials: 'same-origin'
  });
  const json = await result.json();
  const countryCodeData = json.data && json.data.countrycode;
  return countryCodeData;
};